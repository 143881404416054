import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Branch, HierarchicalLevel, MsDataService, UserService} from '@isifid/core';
import {GiftService} from '../../../../../shared/services/gift.service';
import {MatSort} from '@angular/material/sort';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidator} from '../../../../../shared/helpers/custom.validator';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-branch-list',
    templateUrl: './list.component.html',
    standalone: false
})
export class BranchListComponent implements OnInit {
    loading = true;
    branches: Array<Branch>;
    dataSource = new MatTableDataSource<any>();
    displayedColumns: Array<string>;
    displayedSimpleColumns: Array<string>;
    displayAllColumns = false;
    filterForm: FormGroup;
    hierarchicalLevels: Array<HierarchicalLevel>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public readonly giftService: GiftService,
        private readonly formBuilder: FormBuilder,
        private readonly msDataService: MsDataService,
        public readonly userService: UserService,
        private readonly _snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
        // Get levels and remove the first & last elements (advisor & HQ)
        this.hierarchicalLevels = giftService.hierarchicalLevels.slice(1, giftService.hierarchicalLevels.length - 1)
            .sort((a, b) => b.position - a.position);
        this.displayedSimpleColumns = ['externalId', 'name', 'parentBranchExternalId', 'echelon', 'action'];
        this.displayedColumns = ['externalId', 'name', 'parentBranchExternalId', 'address1', 'address2', 'zipCode',
            'city', 'email', 'phone', 'echelon', 'action'];

        this.filterForm = this.formBuilder.group({
            externalId: ['', [Validators.maxLength(5), CustomValidator.isBranchCode]],
            name: '',
            parentBranchExternalId: '',
            levelId: 0,
            status: ''
        });
    }

    ngOnInit() {
        this.msDataService.getBranchesByClientId(this.giftService.settings.clientId).subscribe({
            next: branches => this.branches = branches,
            error: () => {
                this.branches = [];
                this.setData();
            }, complete: () => this.setData()
        });
    }

    private setData() {
        this.dataSource = new MatTableDataSource<unknown>(this.branches);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loading = false;
    }

    filterBranches() {
        if (this.filterForm.invalid) return;
        this.dataSource = new MatTableDataSource<unknown>(this.branches);
        let filteredBranches = [];

        if (this.filterForm.get('externalId').value.trim()) {
            filteredBranches = this.dataSource.data.filter((u: any) => {
                return u.externalId.toString().includes(this.filterForm.get('externalId').value.trim());
            });
        }
        if (this.filterForm.get('name').value.trim()) {
            filteredBranches = this.dataSource.data.filter((u: any) => {
                return u.name.toLowerCase().includes(this.filterForm.get('name').value.toLowerCase());
            });
        }
        if (this.filterForm.get('parentBranchExternalId').value.trim()) {
            filteredBranches = this.dataSource.data.filter((u: any) => {
                return u.parentBranchExternalId?.includes(this.filterForm.get('parentBranchExternalId').value.trim());
            });
        }
        const levelId = this.filterForm.get('levelId').value;
        if (levelId && levelId > 0) {
            filteredBranches = this.dataSource.data.filter((u: any) => {
                return u.levelId === levelId;
            });
        }
        const status = this.filterForm.get('status').value;
        if (status) {
            filteredBranches = this.dataSource.data.filter((u: any) => {
                return u.status.includes(status);
            });
        }

        this.dataSource = new MatTableDataSource<unknown>(filteredBranches);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    getLevelName(levelId) {
        const level = this.giftService.hierarchicalLevels.find(l => l.id === levelId);
        if (level) return level.entity;
        else return 'Problème d\'échelon';
    }

    openDeleteBranchDialog(branch: Branch) {
        const dialogRef = this.dialog.open(DialogDeleteBranchComponent, {
            width: '500px',
            data: {branch: branch}
        });
        dialogRef.afterClosed().subscribe({
            next: (result: boolean) => {
                if (result === true) {
                    this.msDataService.deleteBranch(branch.id).subscribe({
                        next: () => {
                            this._snackBar.open('L\'entité a été supprimée avec succès', 'X');
                            this.ngOnInit();
                        }
                    });
                }
            }
        });
    }
}

@Component({
    selector: 'app-dialog-delete-branch',
    templateUrl: 'dialog-delete-branch.html',
    standalone: false
})
export class DialogDeleteBranchComponent {
    constructor(
        private dialogRef: MatDialogRef<DialogDeleteBranchComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDeleteBranchData
    ) {
    }

    deleteBranch() {
        this.dialogRef.close(true);
    }
}

export interface DialogDeleteBranchData {
    branch: Branch;
}
