import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomRewardField, Offer, Operation} from '@isifid/core';

import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';

import {CustomFieldService} from '../../../../shared/services/custom-field.service';
import {OperationsService} from '../../../../shared/services/operations.service';
import {finalize, Observable, of, switchMap, tap} from 'rxjs';

@Component({
    selector: 'app-operations-custom-fields',
    templateUrl: './custom-fields.component.html',
    standalone: false
})
export class OperationsCustomFieldsComponent implements OnInit {
    addCustomFieldForm: FormGroup;
    customRewardFormFields: Array<CustomRewardField & {offerId?: number}>;
    dataSource = new MatTableDataSource<any>();
    displayedColumns = ['offerId', 'order', 'name', 'type', 'saveInDatabase', 'required', 'placeholder', 'hint', 'action'];
    isLoading: 'all' | 'add' | 'update' | 'delete' = 'all';
    offers: Array<Offer>;
    operations: Operation[];
    selectedOperation: Operation;
    selectedOfferId: number;
    updateCustomFieldForm: FormGroup;
    customFieldSelected: any;


    constructor(
        private readonly customFieldService: CustomFieldService,
        private readonly formBuilder: FormBuilder,
        private readonly operationsService: OperationsService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
        this.operations = this.operationsService.operations;
        this.isLoading = null;
    }

    changeOperation(event: MatSelectChange) {
        this.isLoading = 'all';
        this.offers = null;
        this.selectedOfferId = null;
        this.selectedOperation = this.operations.find(o => o.id === event.value);
        this.refreshOffers()
            .pipe(finalize(() => this.isLoading = null))
            .subscribe();
    }

    changeOffer() {
        this.getCustomRewardFields();
        this.initAddCustomFieldForm();
    }

    addCustomField() {
        if (this.addCustomFieldForm.invalid) return;
        this.isLoading = 'add';
        const offer = this.offers.find(s => Number(s.id) === this.selectedOfferId);

        this.customFieldService.addCustomField(this.addCustomFieldForm.getRawValue(), offer.configuration, this.selectedOperation.id )
            .pipe(
                switchMap(() => this.refreshOffers()),
                finalize(() => this.isLoading = null)
            )
            .subscribe({
                next: () => this._snackBar.open('Champ : ' + this.addCustomFieldForm.get('name').value + ' ajouté', 'X'),
                error: () => console.error('error while adding custom field')
            });
    }

    updateCustomField() {
        if (this.updateCustomFieldForm.invalid) return;
        this.isLoading = 'update';
        const offer = this.offers.find(s => Number(s.id) === this.selectedOfferId);

        this.customFieldService.updateCustomField(
            {...this.customFieldSelected}, this.updateCustomFieldForm.getRawValue(), offer.configuration, this.selectedOperation.id
        )
            .pipe(
                switchMap(() => this.refreshOffers()),
                finalize(() => this.isLoading = null)
            )
            .subscribe({
                next: () => this._snackBar.open('Champ modifié', 'X'),
                error: () => console.error('error while updating custom field')
            });
    }

    deleteCustomField(field: CustomRewardField & {offerId?: number}) {
        this.isLoading = 'delete';
        const offer = this.offers.find(s => Number(s.id) === this.selectedOfferId);

        this.customFieldService.deleteCustomField(field, offer.configuration, this.selectedOperation.id)
            .pipe(
                switchMap(() => this.refreshOffers()),
                finalize(() => this.isLoading = null)
            )
            .subscribe({
                next: () => this._snackBar.open('Champ suprimé', 'X'),
                error: () => console.error('error while deleting a custom field')
            });
    }

    initUpdateCustomFieldForm(event: MatSelectChange) {
        this.customFieldSelected = event.value;
        this.updateCustomFieldForm = this.formBuilder.group({
            order: [event.value.order, Validators.required],
            name: [event.value.name, Validators.required],
            required: [event.value.required],
            type: [event.value.type, Validators.required],
            defaultValue: [event.value.defaultValue],
            placeholder: [{value: event.value.placeholder, disabled: true}],
            hint: [{value: event.value.hint || '', disabled: true}],
            minLength: [event.value.minLength],
            maxLength: [event.value.maxLength],
            saveInDatabase: [event.value.saveInDatabase]
        });
    }

    updateType(event: MatSelectChange) {
        if (event.value === 'link') {
            this.addCustomFieldForm.get('required').setValue(false);
            this.addCustomFieldForm.get('saveInDatabase').setValue(false);
            this.addCustomFieldForm.get('required').disable();
            this.addCustomFieldForm.get('saveInDatabase').disable();
            this.addCustomFieldForm.get('minLength').disable();
            this.addCustomFieldForm.get('maxLength').disable();
        } else {
            this.addCustomFieldForm.get('required').enable();
            this.addCustomFieldForm.get('saveInDatabase').enable();
            this.addCustomFieldForm.get('minLength').enable();
            this.addCustomFieldForm.get('maxLength').enable();
        }
    }

    private getCustomRewardFields(): void {
        this.customRewardFormFields = this.customFieldService
            .getCustomFieldsFromConfiguration(this.selectedOperation.configuration);
        if (this.selectedOfferId) {
            const offer = this.offers.find(s => Number(s.id) === this.selectedOfferId);

            this.customRewardFormFields = [
                ...this.customRewardFormFields,
                ...this.customFieldService.getCustomFieldsFromConfiguration(offer.configuration)
                    .map(s => ({...s, offerId: this.selectedOfferId}))
            ];
        }

        this.dataSource = new MatTableDataSource<unknown>(this.customRewardFormFields);
    }

    private initAddCustomFieldForm() {
        this.addCustomFieldForm = this.formBuilder.group({
            order: [1, Validators.required],
            offerId: [this.selectedOfferId, Validators.required],
            name: ['', Validators.required],
            required: [false],
            type: ['text', Validators.required],
            defaultValue: [''],
            placeholder: [{value: '', disabled: true}],
            hint: [{value: '', disabled: true}],
            minLength: [''],
            maxLength: [''],
            saveInDatabase: [false]
        });
    }

    private refreshOffers(): Observable<Offer[]> {
        if (!this.selectedOperation) return of(null);
        return this.operationsService.getOffersByOperationId(this.selectedOperation.id)
            .pipe(
                tap(offers => {
                    this.offers = offers;
                    this.customFieldSelected = null;
                    this.getCustomRewardFields();
                })
            );
    }
}
