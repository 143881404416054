import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Branch, Company, HierarchicalLevel, MsDataService} from '@isifid/core';
import {GiftService} from '../../../../../shared/services/gift.service';
import {forkJoin, Observable} from 'rxjs';
import {ExcelService} from '@isifid/reward';
import {MatTableDataSource} from '@angular/material/table';

class FileBranch {
    public branch: Branch;
    public update: boolean;
    public error: string;
    public infos: string;
}

@Component({
    selector: 'app-branch-manage',
    templateUrl: './import.component.html',
    standalone: false
})
export class BranchImportComponent implements OnInit {
    loading = true;
    loadingFile = false;
    fileLoaded = false;
    displayAllColumns = false;
    savingFile = false;
    error: string;
    saveSuccessful = false;
    levelId: number;

    clientCompany: Company;
    clientBranches: Branch[];
    fileBranches: FileBranch[] = [];

    dataSource = new MatTableDataSource<any>();
    displayedColumns = ['Code entité', 'Nom entité', 'Code entité supérieure', 'Adresse 1', 'Adresse 2', 'Code postal', 'Ville', 'Email', 'Téléphone', 'Statut',
        'Infos'];
    displayedSimpleColumns = ['Code entité', 'Nom entité', 'Code entité supérieure', 'Statut', 'Infos'];
    colorRed = '#ff4242';
    colorGreen = '#00ba00';
    hierarchicalLevels: Array<HierarchicalLevel>;

    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(
        private readonly msDataService: MsDataService,
        public readonly giftService: GiftService,
        private readonly excelService: ExcelService
    ) {
        this.hierarchicalLevels = giftService.hierarchicalLevels.slice(1, giftService.hierarchicalLevels.length - 1).sort((a, b) => b.position - a.position);
        if (this.hierarchicalLevels) this.levelId = this.hierarchicalLevels[0].id;
    }

    ngOnInit() {
        forkJoin([
            this.msDataService.getCompanyByClientId(this.giftService.client.id),
            this.msDataService.getBranchesByClientId(this.giftService.client.id)
        ]).subscribe({
            next: ([companyData, branchesData]) => {
                this.clientCompany = companyData;
                this.clientBranches = branchesData;
            }, error: () => {
                this.clientCompany = new Company();
                this.clientBranches = [];
                this.loading = false;
            }, complete: () => this.loading = false
        });
    }

    onFileChange(event: any): void {
        const target: DataTransfer = (event.target) as DataTransfer;
        this.error = null;
        this.loadingFile = true;
        if (target.files.length !== 0) {
            this.excelService.importFromExcelFileEvent(event).subscribe(fileData => {
                this.mapFileContentToBranches(fileData);
                if (!this.error) this.checkFileBranches();
                if (this.fileBranches.length > 0) this.fileLoaded = true;
                this.loadingFile = false;
            });
        } else {
            this.loadingFile = false;
        }
    }

    mapFileContentToBranches(fileContent: any[]) {
        if (!fileContent.length) {
            this.error = 'Le fichier est vide';
            return;
        }
        fileContent[0].forEach((columnName, i) => {
            if (columnName !== this.displayedColumns[i]) this.error = 'Les colonnes du fichier ne correspondent pas au modèle';
        });
        if (this.error) return;

        fileContent.slice(1).forEach((branchArray: any[]) => {
            // Dont consider empty lines
            if (!branchArray.length) return;

            const branch = new Branch();
            branch.companyId = this.clientCompany.id;
            branch.externalId = this.giftService.convertBranchCodeToString(branchArray[0]);
            branch.name = this.upper(branchArray[1]);
            branch.parentBranchExternalId = this.giftService.convertBranchCodeToString(branchArray[2]);
            branch.address1 = this.upper(branchArray[3]);
            branch.address2 = this.upper(branchArray[4]);
            branch.zipCode = branchArray[5];
            branch.city = this.upper(branchArray[6]);
            branch.email = branchArray[7];
            branch.phone = branchArray[8];
            branch.status = branchArray[9] ? branchArray[9] : '';

            const fileBranch = new FileBranch();
            fileBranch.branch = branch;
            this.fileBranches.push(fileBranch);
        });
    }

    checkFileBranches() {
        this.fileBranches.forEach(fileBranch => {
            delete fileBranch.error;
            delete fileBranch.infos;

            fileBranch.error = this.setFileBranchError(fileBranch);
            if(fileBranch.error) return;

            const sameNameBranch = this.clientBranches.find(b => b.name === fileBranch.branch.name);
            if (sameNameBranch) {
                fileBranch.branch.id = sameNameBranch.id;
                fileBranch.update = true;
            }

            const sameExternalIdBranch = this.clientBranches.find(b => b.externalId === fileBranch.branch.externalId);
            if (sameExternalIdBranch && !sameNameBranch) fileBranch.error = 'Ce code entité existe déjà';

            if (fileBranch.branch.parentBranchExternalId) {
                const parentBranch = this.clientBranches.find(b => b.externalId === fileBranch.branch.parentBranchExternalId);
                if (!parentBranch) fileBranch.error = 'Ce code entité supérieure n\'existe pas';
            } else fileBranch.branch.parentBranchExternalId = null;

            if (fileBranch.update) fileBranch.infos = 'Mise à jour';
            else fileBranch.infos = 'Création';
        });

        this.dataSource = new MatTableDataSource<unknown>(this.fileBranches);
    }

    private setFileBranchError(fileBranch): string {

        if (!fileBranch.branch.externalId) {
            return 'Le code entité est obligatoire';
        }

        if (!fileBranch.branch.name) {
            return 'Le nom est obligatoire';
        }

        if (fileBranch.branch.zipCode && !/^\d{5}$/.exec(fileBranch.branch.zipCode)) {
            return 'Le code postal est erroné';
        }

        if (fileBranch.branch.email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.exec(fileBranch.branch.email)) {
            return 'L\'email est erroné';
        }

        if (fileBranch.branch.phone && !/^d{10}$/.exec(fileBranch.branch.phone)) {
            return 'Le téléphone est erroné';
        }

        if (fileBranch.branch.status
            && fileBranch.branch.status !== ''
            && fileBranch.branch.status !== 'active'
            && fileBranch.branch.status !== 'merged'
            && fileBranch.branch.status !== 'deleted') {
            return 'Le statut est erroné';
        }

        return '';
    }

    save() {
        const branchObservables: Observable<Branch>[] = [];
        const branchesToCreate = [];

        this.fileBranches.forEach(fileBranch => {
            const branch = fileBranch.branch;
            branch.levelId = this.levelId;
            if (fileBranch.update) {
                branchObservables.push(this.msDataService.updateBranch(branch.id, branch));
            } else {
                branchesToCreate.push(branch);
            }
        });
        if (branchesToCreate.length > 0) {
            const data = {
                'companyId': this.clientCompany.id,
                'branches': branchesToCreate
            };
            branchObservables.push(this.msDataService.createBranches(this.giftService.client.id, data));
        }

        this.error = null;
        this.savingFile = true;
        forkJoin(branchObservables).subscribe({
            next: () => {
                this.reset();
                this.savingFile = false;
                this.saveSuccessful = true;
                this.fileLoaded = null;
            }, error: () => {
                this.error = 'Erreur lors de la sauvegarde des entités, veuillez ressayer ultérieurement';
                this.savingFile = false;
            }
        });
    }

    hasFileErrors(): boolean {
        for (const fileBranch of this.fileBranches) {
            if (fileBranch.error) return true;
        }
        return false;
    }

    reset() {
        this.fileBranches = [];
        if (this.fileInput) this.fileInput.nativeElement.value = '';
        this.dataSource = new MatTableDataSource<any>();
        this.error = null;
        this.fileLoaded = false;
    }

    upper(str: string): string {
        return str ? str.toUpperCase().trim() : null;
    }

    downloadModel() {
        window.open('https://cdn.isifid.com/common/Modele-import-entites.xlsx', '_blank', 'noopener');
    }

    setLevel(levelId) {
        this.levelId = levelId;
        this.checkFileBranches();
    }
}
