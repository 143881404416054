import {Component} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService, DateService, UserService} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import {UIService} from '../../../../shared/services/ui.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-operation-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss'],
    standalone: false
})
export class OperationsRequestComponent {
    isOperationValid: boolean;
    isRewardValid: boolean;
    isTimeFrameValid: boolean;
    operationForm: FormGroup;
    sent: boolean;
    loading = true;

    constructor(
        private readonly apiService: ApiService,
        private readonly dateService: DateService,
        private readonly formBuilder: FormBuilder,
        private readonly giftService: GiftService,
        private readonly userService: UserService,
        private readonly uiService: UIService,
        private readonly _snackBar: MatSnackBar
    ) {
        const endAt = new Date();
        endAt.setMonth(endAt.getMonth() + 6);
        this.operationForm = this.formBuilder.group({
            eventType: '-',
            eventCustomName: '',
            eventName: '',
            legal: '',
            text: '',
            observation: '',
            behaviourType: '-',
            behaviourCustomName: '',
            subscription: '',
            subscriptionType: '-',
            subscriptionCustomName: '',
            multipleOffers: -1,
            maxSponsored: 0,
            offers: this.formBuilder.array([this.formBuilder.group({name: '', price: [0, [Validators.required, Validators.min(0)]]})]),
            isIsifidReward: -1,
            rewardTypeId: -1,
            rewardTypeCustom: '',
            startAt: [new Date(), Validators.required],
            endAt: [endAt, Validators.required],
            rewardProcess: '',
            rewardLevel: 'direction d\'agences',
            consumerCreationProcess: '',
            sponsorAmount: 0,
            sponsoredAmount: 0,
            budget: this.formBuilder.group({
                budgetAmount: [0, Validators.min(0)],
                budgetAmountPerAdvisor: [0, Validators.min(0)]
            })
        });
        this.checkIfOperationValid();
        this.checkIfRewardValid();
        this.checkIfTimeFrameValid();
        this.loading = false;
    }

    get offers(): FormArray<FormGroup> {
        return this.operationForm.controls['offers'] as FormArray;
    }

    isEventValid(): boolean {
        const eventType = this.operationForm.get('eventType').value;
        const behaviourType = this.operationForm.get('behaviourType').value;
        const behaviourCustomName = this.operationForm.get('behaviourCustomName').value;
        const eventCustomName = this.operationForm.get('eventCustomName').value;
        const eventName = this.operationForm.get('eventName').value;

        if (eventType === '-') return false;
        return eventType === 'neither' ||
            (eventType === 'action' && behaviourType !== '-' && (behaviourType !== 'custom' || behaviourCustomName !== '') ||
            (eventType === 'event' && eventCustomName !== '' && (eventCustomName !== 'custom' || eventName !== '')));
    }

    isBehaviourValid(): boolean {
        if (this.operationForm.get('eventType').value === 'neither' || this.operationForm.get('eventType').value === 'event') return true;
        else if (this.operationForm.get('behaviourType').value === '-') return false;

        return this.operationForm.get('behaviourType').value !== 'custom' ||
            (this.operationForm.get('behaviourType').value === 'custom' && this.operationForm.get('behaviourCustomName').value !== '');
    }

    isSubscriptionValid(): boolean {
        if (!this.operationForm.get('subscription').value || this.operationForm.get('subscriptionType').value === 'Mobilité bancaire') return true;
        else if (this.operationForm.get('subscriptionType').value === '-') return false;
        else return this.operationForm.get('subscriptionCustomName').value !== '';
    }

    checkIfOperationValid() {
        if (this.operationForm.get('eventType').value === '0' && this.operationForm.get('behaviourType').value === '0' &&
            this.operationForm.get('subscriptionType').value === '0') {
            this.isOperationValid = false;
        } else {
            this.isOperationValid = this.isEventValid() && this.isBehaviourValid() && this.isSubscriptionValid();
        }

        // If multiple offers and only one offer, add another one
        if (this.operationForm.get('multipleOffers').value === '1' && this.offers.length === 1) {
            this.addOffer();
            // If not multiple offers and more than one offer, remove all offers except the first one
        } else if (this.operationForm.get('multipleOffers').value === 0 && this.offers.length > 1) {
            while (this.offers.length > 1) this.offers.removeAt(this.offers.length - 1);
        }
    }

    checkIfTimeFrameValid(): void {
        const startAt = this.operationForm.get('startAt').value;
        const endAt = this.operationForm.get('endAt').value;
        if (!startAt || !endAt || startAt === '' || endAt === '' || endAt < startAt) {
            this.operationForm.get('startAt').setErrors({wrongTimeFrame: true});
            this.isTimeFrameValid = false;
        } else {
            this.operationForm.get('startAt').setErrors(null);
            this.isTimeFrameValid = true;
        }
    }

    checkIfRewardValid(): void {
        const isIsifidReward = this.operationForm.get('isIsifidReward').value;
        const rewardTypeId = this.operationForm.get('rewardTypeId').value;
        const rewardTypeCustom = this.operationForm.get('rewardTypeCustom').value;

        this.isRewardValid = +rewardTypeId > -1 || (isIsifidReward === '0' && rewardTypeCustom != '');
    }

    submit(): void {
        if (!this.isOperationValid) return;
        this.loading = true;
        const recipients = environment.emailRecipientsBusiness;
        const email = {
            sender: `Gift 🎁 ${this.giftService.client.name} <gift+operation@isifid.com>`,
            recipients: recipients,
            // Must be base64 encoded
            body: btoa(this.formatEMail().replace('=', '')),
            subject: `Demande opération : ${this.giftService.client.name} 📝`,
            attachments: null
        };
        this.apiService.post('/utils/email/send', email).subscribe({
            next: () => this._snackBar.open('Demande de création d\'une nouvelle opération envoyée !', 'Fermer', {duration: 2000}),
            error: () => console.error('Error while sending email'),
            complete: () => {
                this.sent = true;
                this.loading = false;
            }
        });
    }

    formatEMail(): string {
        let body = `<h1>Demande d'operation :</h1><h2>Client : ${this.giftService.client.name} ,id: ${this.giftService.client.id}</h2>`;
        body += `Nature de l'opération : `;
        body += this.setBodyByEvenType();
        body += `Récompense conditionnée à la souscription d'un produit ou service financier : `;
        body += this.setBodyBysubscription();
        body += `Récompense en cartes cadeaux Isifid ? : ${this.operationForm.get('isIsifidReward').value === 1 ? 'Oui' : 'Non'}<br>`;
        let rewardType: string = '';
        switch (this.operationForm.get('rewardTypeId').value) {
            case '0':
                rewardType = 'Regular';
                break;
            case '2':
                rewardType = 'Premium';
                break;
            case '3':
                rewardType = 'Platinium';
                break;
            case '10':
                rewardType = 'Cash';
                break;
            case '11':
                rewardType = 'Remise sur vos produits';
                break;
            case '12':
                rewardType = 'Autres cartes cadeaux';
                break;
            case '13':
                rewardType = this.operationForm.get('rewardTypeCustom').value;
                break;
            default:
                break;
        }
        if (rewardType !== '') body += `Type de récompense : ${rewardType}<br>`;

        body += this.setBodyByBehaviourType();

        body += `<h3>Durée de l'opération :</h3>`;
        body += `Début : ${this.dateService.computeDate(this.convertDateTime(this.operationForm.get('startAt').value), 'dd-MM-yyyy')}<br>`;
        body += `Fin : ${this.dateService.computeDate(this.convertDateTime(this.operationForm.get('endAt').value), 'dd-MM-yyyy')}<br><br>`;

        body += `<h3>Mode d'attribution des récompenses :</h3><br>`;
        
        body += this.setBodyByProcess();

        if (this.operationForm.get('rewardLevel').value) body += ` Récompenses réparties par ${this.operationForm.get('rewardLevel').value}<br><br>`;

        if (this.operationForm.get('budget').get('budgetAmount').value) {
            body += `Opération avec budget agence<br>`;
            body += `Montant du budget agence ${this.operationForm.get('budget').get('budgetAmount').value} €<br><br>`;
        }

        if (this.operationForm.get('legal').value) {
            body += `Mentions légales : <br>`;
            body += `${this.operationForm.get('legal').value}<br><br>`;
        }

        if (this.operationForm.get('text').value) {
            body += `Texte libre : <br>`;
            body += `${this.operationForm.get('text').value}<br><br>`;
        }

        if (this.operationForm.get('observation').value) {
            body += `Observation : <br>`;
            body += `${this.operationForm.get('observation').value}<br><br>`;
        }

        body += `Demande effectuée par : ${this.userService.getUser().firstName || ''} ${this.userService.getUser().lastName || ''} 
        ${this.userService.getUser().email} ${this.userService.getUser().phone || ''}<br><br> Merci de ne pas répondre à cet email`;
        return body;
    }

    removeLastOffer(): void {
        // Always keep 2 offers
        if (this.offers.length > 2) this.offers.removeAt(this.offers.length - 1);
    }

    addOffer(): void {
        // Max 10 offers
        if (this.offers.length >= 10) return;
        const offerFOrm = this.formBuilder.group({
            name: new FormControl('', [Validators.required]),
            price: new FormControl(0, [Validators.required, Validators.min(0)])
        });
        this.offers.push(offerFOrm);
    }

    totalReward(): string {
        // budgetAmount / 40%
        const budgetAmount = this.operationForm.get('budget').get('budgetAmount').value || 0;
        const total = budgetAmount / 0.4;
        return total.toString();
    }

    rewardByAdvisor(): string {
        // we use 500
        return (Number(this.totalReward()) * 4 / 500).toString();
    }

    updateSubscription(event): void {
        this.operationForm.get('subscriptionCustomName').setValue('');
        if (event.value === 'true') this.operationForm.get('subscriptionType').setValue('-');
        else this.operationForm.get('subscriptionType').setValue('0');

        this.checkIfOperationValid();
    }

    private convertDateTime(value: string): string {
        const date = new Date(value);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date as unknown as string;
    }

    private setBodyByEvenType() {
        let body = '';
        if (this.operationForm.get('eventType').value === 'event') {
            body += `Opération relative à un évènement <br>`;
            if (this.operationForm.get('eventCustomName').value != 'custom') body += `Evènement : ${this.operationForm.get('eventCustomName').value}<br>`;
            else body += `Evènement personalisé : ${this.operationForm.get('eventName').value}<br>`;
        } else if (this.operationForm.get('eventType').value === 'action') {
            body += `Opération relative à une action <br>`;
            if (this.operationForm.get('behaviourType').value != 'custom') body += `Action : ${this.operationForm.get('behaviourType').value}<br>`;
            else body += `Action personalisée : ${this.operationForm.get('behaviourCustomName').value}<br>`;
        } else body += `Opération relative ni à un évènement ni à une action.<br>`;
        return body;
    }

    private setBodyBysubscription() {
        let body = '';
        if (this.operationForm.get('subscription').value) {
            body += `Oui <br>`;
            if (this.operationForm.get('subscriptionType').value != 'custom') {
                body += `Type de souscription : ${this.operationForm.get('subscriptionType').value}<br>`;
                if (this.operationForm.get('subscriptionType').value !== 'Mobilité bancaire') {
                    body += `Souscription à : ${this.operationForm.get('subscriptionCustomName').value}<br>`;
                }
            } else body += `Type de souscription personalisé : ${this.operationForm.get('subscriptionCustomName').value}<br>`;
        } else body += `Non <br>`;
        return body;
    }

    private setBodyByBehaviourType() {
        let body = '';
        if (this.operationForm.get('behaviourType').value !== 'Parrainage / Recommandation') {
            body += `<h3>Offres :</h3>`;
            this.operationForm.get('offers').value.forEach((offer, index) => {
                body += `Offre ${index + 1} : ${offer.name} ${offer.price}€<br>`;
            });
        }

        if (this.operationForm.get('behaviourType').value === 'Parrainage / Recommandation') {
            body += `Montant de la récompense Parrain ${this.operationForm.get('sponsorAmount').value}€<br>`;
            body += `Nombre maximum de filleuls ${this.operationForm.get('maxSponsored').value}<br>`;
            body += `Montant de la récompense Filleul ${this.operationForm.get('sponsoredAmount').value}€<br>`;
        }
        return body;
    }

    private setBodyByProcess() {
        let body = '';
        if (this.operationForm.get('rewardProcess').value === 'always') {
            if (this.operationForm.get('consumerCreationProcess').value === 'file') {
                body += `Par transmission de fichier en fin d'opération<br>`;
            } else body += `Par saisie de chaque bénéficiaire par les conseillers<br>`;
        } else if (this.operationForm.get('consumerCreationProcess').value === 'platform') {
            body += `À l'initiative de chaque conseiller, sans contrainte budgétaire<br>`;
        } else body += `À l'initiative de chaque conseiller, avec contrainte budgétaire<br><br>`;
        return body;
    }
}
