import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {InvoiceService, MsToolsBillingService, Payment} from '@isifid/core';
import {GiftService} from '../../../shared/services/gift.service';
import {FormBuilder} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    standalone: false
})
export class BillingComponent {
    loading: boolean;
    paymentHistory: any;
    dataSource: any;
    displayedColumns = ['createdAt', 'amount', 'paymentMethod', 'paidAt', 'invoice'];
    @ViewChild(MatSort) sortBilling: MatSort;

    constructor(
        private cdRef: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly giftService: GiftService,
        private readonly invoiceService: InvoiceService,
        private readonly msToolsBillingService: MsToolsBillingService
    ) {
        this.loading = true;
        this.msToolsBillingService.getPayments({clientId: this.giftService.client.id}).subscribe({
            next: paymentHistory => {
                this.paymentHistory = paymentHistory.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
                this.dataSource = new MatTableDataSource<unknown>(this.paymentHistory);
                this.dataSource.sort = this.sortBilling;
                this.cdRef.detectChanges();
            }, error: () => {
                console.error('Error while getting payments');
                this.loading = false;
            }, complete: () => this.loading = false
        });
    }

    downloadPdf(payment: Payment) {
        this.msToolsBillingService.getInvoiceFile(payment.invoiceId).subscribe({
            next: (response) => {
                this.msToolsBillingService.getInvoice(payment.invoiceId).subscribe({
                    next: (invoice) => {
                        const name = this.invoiceService.getName(invoice, payment.clientId);
                        this.invoiceService.downloadFile(response, name);
                    }, error: () => console.error('Error while getting invoice')
                });
            }, error: () => console.error('Error while getting invoiceFile')
        });
    }

    getPaymentMethodToString(paymentMethod: number): string {
        switch (paymentMethod) {
        case 0:
            return 'carte bancaire';
        case 1:
            return 'chèque';
        case 2:
            return 'virement';
        case 3:
            return 'prélèvement';
        case 4:
            return 'code d\'achat';
        }
        return '';
    }
}
