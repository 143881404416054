import {Component, OnInit} from '@angular/core';
import {Client, MsClientsService} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-parameters',
    templateUrl: './parameters.component.html',
    standalone: false
})
export class ParametersComponent implements OnInit {
    client: Client;
    clientForm: FormGroup;
    loading: boolean = true;
    private clientId: number;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly giftService: GiftService,
        private readonly msClientsService: MsClientsService,
        private readonly _snackBar: MatSnackBar
    ) {
        this.clientId = this.giftService.client.id;
    }

    ngOnInit(): void {
        this.client = this.giftService.client;
        this.initForm();
        this.loading = false;
    }

    initForm(): void {
        this.clientForm = this.formBuilder.group({
            id: [this.client.id, Validators.required],
            name: [this.client.name, Validators.required],
            legalNotices: [this.client.legalNotices, Validators.required],
            domain: [{value: this.client.domain, disabled: true}, Validators.required],
            phone: [this.client['phone']],
            smsLabel: [this.client.smsLabel, Validators.required],
            email: [{value: this.client.email, disabled: true}, Validators.required],
            emailSenderName: [this.client.emailSenderName, Validators.required],
            emailSignature: [this.client.emailSignature]
        });
    }

    update() {
        if (this.clientForm.invalid || this.clientForm.pristine) return;

        this.loading = true;
        this.msClientsService.updateClient(this.client.id, this.clientForm.getRawValue())
            .subscribe({
                next: () => {
                    this._snackBar.open('Client mis à jour', 'X');
                    Object.assign(this.client, this.clientForm.getRawValue());
                }, error: () => {
                    this._snackBar.open('Une erreur est survenue, merci de réessayer', 'X');
                    console.error('Error while updating client parameters');
                    this.loading = false;
                }, complete: () => {
                    this.giftService.initClient(this.clientId).subscribe({
                        next: () => this.loading = false
                    });
                }
            });
    }
}
