import {Component, OnInit} from '@angular/core';
import {
    DateService, EMAIL_AUTOMATION_RULE_EVENT, EMAIL_AUTOMATION_RULE_ROLE,
    EmailAutomationRule, EmailAutomationRuleEvent, EmailAutomationRuleRole, EmailTemplate,
    MsServicesGiftService,
    MsServicesOperationsService,
    MsUtilsEmailService,
    Operation
} from '@isifid/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {GiftService} from '../../../../../shared/services/gift.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-automation-rule-manage',
    templateUrl: './manage.component.html',
    standalone: false
})
export class AutomationRuleManageComponent implements OnInit {
    createMode = false;
    loading = true;
    operation: Operation;
    templates: EmailTemplate[] = [];
    rule: EmailAutomationRule;
    form: FormGroup;

    events: EmailAutomationRuleEvent[] = [EMAIL_AUTOMATION_RULE_EVENT.CREDIT_DECREASE, EMAIL_AUTOMATION_RULE_EVENT.CREDIT_NOT_USED,
        EMAIL_AUTOMATION_RULE_EVENT.CREDIT_REALLOCATION, EMAIL_AUTOMATION_RULE_EVENT.OPERATION_START_WITH_BUDGET];
    roles: EmailAutomationRuleRole[] = [
        EMAIL_AUTOMATION_RULE_ROLE.GIFT_ADVISOR, EMAIL_AUTOMATION_RULE_ROLE.GIFT_MANAGER,
        EMAIL_AUTOMATION_RULE_ROLE.GIFT_DIRECTOR, EMAIL_AUTOMATION_RULE_ROLE.GIFT_HQ
    ];
    positions: number[] = [0, 1, 2, 3, 4];

    constructor(
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly msServicesOperationsService: MsServicesOperationsService,
        private readonly msUtilsEmailService: MsUtilsEmailService,
        public readonly giftService: GiftService,
        private readonly dateService: DateService,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly _snackBar: MatSnackBar,
        private readonly router: Router
    ) {
    }

    ngOnInit() {
        const operationId = Number(this.route.snapshot.paramMap.get('operationId'));
        const ruleId = Number(this.route.snapshot.paramMap.get('ruleId'));
        if (!ruleId) this.createMode = true;

        forkJoin([
            this.msServicesOperationsService.getOperation(operationId),
            this.msUtilsEmailService.getTemplates()
        ]).subscribe({
            next: ([operation, templates]) => {
                this.operation = operation;
                this.templates = templates.filter((template: EmailTemplate) => template.description === 'automation');

                if (!this.createMode) {
                    this.msServicesGiftService.getEmailAutomationRule(ruleId).subscribe((rule) => {
                        this.rule = rule;
                        this.initForm();
                    });
                } else {
                    this.rule = new EmailAutomationRule();
                    this.initForm();
                }
            }
        });
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            event: [this.rule.event, Validators.required],
            rolesTargeted: this.buildCheckboxFormGroup(this.roles, this.rule.rolesTargeted),
            positionsTargeted: this.buildCheckboxFormGroup(this.positions, this.rule.positionsTargeted),
            giftUsersTargeted: this.buildGiftUsersTargetedControl(),
            templateId: [this.rule.templateId, Validators.required],
            status: [this.rule.status, Validators.required],
            schedule: [this.rule.schedule]
        });
        this.form.get('event').valueChanges.subscribe((event) => {
            this.form.get('schedule').clearValidators();
            if (event === 'OPERATION_START_WITH_BUDGET' || event === 'CREDIT_NOT_USED') {
                this.form.get('schedule').setValidators(Validators.required);
            }
            this.form.get('schedule').updateValueAndValidity();
        });
        this.loading = false;
    }

    buildCheckboxFormGroup(dataArray: any[], ruleArray: any[]): FormGroup {
        const group = new FormGroup({});
        dataArray.forEach((data) => {
            const ruleHasControl = ruleArray.includes(data);
            group.addControl(data, new FormControl(ruleHasControl));
        });
        return group;
    }

    buildGiftUsersTargetedControl(): FormControl {
        let str = '';
        this.rule.giftUsersTargeted.forEach((giftUserId, index) => {
            if (index) str += ',';
            str += giftUserId;
        });
        return new FormControl(str);
    }

    submit(): void {
        if (this.form.invalid || this.form.pristine) return;

        Object.assign(this.rule, this.form.getRawValue());
        this.rule.rolesTargeted = this.roles.filter((role) => this.form.get('rolesTargeted').get(role).value);
        this.rule.positionsTargeted = this.positions.filter((position) => this.form.get('positionsTargeted').get(position.toString()).value);
        this.rule.schedule = this.dateService.computeDate(this.rule.schedule, 'yyyy-MM-dd');

        const giftUsersTargeted = this.form.get('giftUsersTargeted').value;
        if (giftUsersTargeted.includes(',')) this.rule.giftUsersTargeted = giftUsersTargeted.split(',').map(Number);
        else if (giftUsersTargeted === '') this.rule.giftUsersTargeted = [];
        else this.rule.giftUsersTargeted = [Number(giftUsersTargeted)];

        if (!this.rule.rolesTargeted?.length && !this.rule.positionsTargeted?.length && !this.rule.giftUsersTargeted?.length) {
            this._snackBar.open('Veuillez choisir au moins une cible', 'OK');
            return;
        }

        if (!this.createMode) this.updateRule();
        else this.createRule();
    }

    private updateRule(): void {
        this.msServicesGiftService.updateEmailAutomationRule(this.rule).subscribe({
            next: () => {
                this.router.navigate(['/admin/automation/operations/' + this.operation.id]).then();
            },
            error: () => {
                console.error('Error while updating rule : ', this.rule);
            },
            complete: () => {
                this._snackBar.open('Règle mise à jour', 'OK');
            }
        });
    }

    private createRule(): void {
        this.rule.clientId = this.giftService.client.id;
        this.rule.operationId = this.operation.id;

        this.msServicesGiftService.createEmailAutomationRule(this.rule).subscribe({
            next: () => {
                this.router.navigate(['/admin/automation/operations/' + this.operation.id]).then();
            },
            error: () => {
                console.error('Error while creating rule : ', this.rule);
            },
            complete: () => {
                this._snackBar.open('Règle creée', 'OK');
            }
        });
    }
}
