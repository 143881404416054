import {Component, OnInit} from '@angular/core';
import {Branch, GiftUser, HierarchicalLevel, MsCoreService, MsDataService, MsServicesGiftService, User} from '@isifid/core';
import {GiftUserService} from '../../../../shared/services/gift-user.service';
import {GiftService} from '../../../../shared/services/gift.service';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-network-control',
    templateUrl: './control.component.html',
    standalone: false
})
export class NetworkControlComponent implements OnInit {
    branches: Branch[];
    levels: HierarchicalLevel[] = [];
    users: User[] = [];
    giftUsers: GiftUser[] = [];

    constructor(
        private readonly msDataService: MsDataService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly msCoreService: MsCoreService,
        private readonly giftUserService: GiftUserService,
        private readonly giftService: GiftService
    ) {
    }

    ngOnInit(): void {
        forkJoin([
            this.msDataService.getBranchesByClientId(this.giftService.settings.clientId),
            this.msServicesGiftService.getHierarchicalLevelsByClientId(this.giftService.settings.clientId),
            this.msCoreService.getUsers([], {clientId: this.giftService.settings.clientId}),
            this.giftUserService.getGiftUsersByClientId(this.giftService.settings.clientId)
        ]).subscribe({
            next: ([branches, levels, users, giftUsers]) => {
                this.branches = branches;
                this.users = users;
                this.giftUsers = giftUsers.map(s => ({...s, branchList: s.branchList ?? []}));
                // Sort array Levels by position in desc order.
                levels.sort((a, b) => b.position - a.position);
                this.levels = levels;
            }
        });
    }
}
