import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Banner, MsServicesGiftService} from '@isifid/core';
import {MatSort} from '@angular/material/sort';
import {finalize, Observable, switchMap} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GiftService} from '../../../../../shared/services/gift.service';
import {OperationsService} from '../../../../../shared/services/operations.service';

@Component({
    selector: 'app-banners-list',
    templateUrl: './list.component.html',
    standalone: false
})
export class BannersListComponent implements OnInit {
    displayedColumns = ['theme', 'name', 'actions'];
    dataSource: MatTableDataSource<Banner>;
    loading: boolean = true;

    private bannersBeingEdited: number[] = [];
    private observer: Observable<any>;

    @ViewChild(MatSort) private sortBanners: MatSort;

    constructor(
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        public readonly giftService: GiftService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
        this.getAllBanners();
    }

    checkBannerBeingEdited(id: number): boolean {
        return this.bannersBeingEdited.includes(id);
    }

    publishBanner(banner: Banner): void {
        if (this.checkBannerBeingEdited(banner.id)) return;
        this.bannersBeingEdited.push(banner.id);

        // Create or update the banner
        banner.status = 'active';
        if (!banner.clientId) {
            const bannerToCreate = {
                clientId: this.giftService.client.id,
                name: banner.name,
                theme: banner.theme,
                srcUrl: banner.srcUrl,
                status: banner.status,
                operationTypeId: banner.operationTypeId
            };
            this.observer = this.msServicesGiftService.createBanner(bannerToCreate).pipe(
                switchMap(() => this.msServicesGiftService.toggleClientFromBanner(banner.id, bannerToCreate.clientId))
            );
        } else {
            this.observer = this.msServicesGiftService.updateBanner(banner.id, banner);
        }

        this.observer
            .pipe(finalize(() => this.removeBannerFromBannersBeingEdited(banner.id)))
            .subscribe({
                next: () => {
                    this._snackBar.open('La bannière a été publiée.', 'X');
                    this.getAllBanners();
                },
                error: () => this._snackBar.open('Une erreur s\'est produite lors de la publication de la bannière.', 'X')
            });
    }

    unPublishBanner(banner: Banner): void {
        if (this.checkBannerBeingEdited(banner.id)) return;
        this.bannersBeingEdited.push(banner.id);

        if (banner.clientId === this.giftService.client.id) {
            banner.status = 'publishable';
            this.observer = this.msServicesGiftService.updateBanner(banner.id, banner);
        } else {
            banner.status = 'publishable';
            const bannerToCreate = {
                clientId: this.giftService.client.id,
                name: banner.name,
                theme: banner.theme,
                srcUrl: banner.srcUrl,
                status: banner.status,
                operationTypeId: banner.operationTypeId
            };
            this.observer = this.msServicesGiftService.createBanner(bannerToCreate).pipe(
                switchMap(() => this.msServicesGiftService.toggleClientFromBanner(banner.id, bannerToCreate.clientId))
            );
        }

        this.observer
            .pipe(finalize(() => this.removeBannerFromBannersBeingEdited(banner.id)))
            .subscribe({
                next: () => {
                    this._snackBar.open('Bannière dépubliée avec succès.', 'X');
                    this.getAllBanners();
                },
                error: () => this._snackBar.open('Une erreur s\'est produite lors de la publication de la bannière.', 'X')
            });
    }

    private removeBannerFromBannersBeingEdited(id: number): void {
        const index = this.bannersBeingEdited.findIndex(s => s === id);
        this.bannersBeingEdited.splice(index, 1);
    }

    private filterBanners(banners: Banner[]): Banner[] {
        const operations = this.operationsService.getOperations(true, true, true);
        const operationsTypes = [...new Set(operations.reduce((acc, ope) => acc.concat(ope.operationType.id), []))];

        // Filter banner to keep active operation with matching operation type
        return banners.filter(b => b.operationTypeId && b.operationTypeId !== -1 ? operationsTypes.includes(b.operationTypeId) : true);
    }

    private getAllBanners(): void {
        this.loading = true;
        this.dataSource = new MatTableDataSource<Banner>([]);
        this.msServicesGiftService.getAllBanners([], {clientId: this.giftService.client.id})
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: banners => {
                    this.dataSource.data = this.filterBanners(banners);
                    this.dataSource.sort = this.sortBanners;
                },
                error: () => this._snackBar.open('Erreur lors de l\'obtention des bannières.', 'X')
            });
    }
}
