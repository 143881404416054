import {Component, Inject, OnInit} from '@angular/core';
import {Client, Offer, Operation} from '@isifid/core';
import {ExcelService, RewardFromFileService, RewardRequestPurl} from '@isifid/reward';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GiftService} from '../../../shared/services/gift.service';
import {OperationsService} from '../../../shared/services/operations.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-reward',
    templateUrl: './reward.component.html',
    standalone: false
})
export class RewardComponent implements OnInit {
    consumers: Array<any>;
    consumersToRewardFromFile = null;
    consumersToRewardFromFileFiltersForm: any;
    consumersToRewardFromFileStats: any;
    fileWithConsumersToRewardLoading: any;
    loading = true;
    operations: Array<Operation> = [];
    rewardBatchProgress: any;
    selectedOperation: Operation = null;
    private client: Client;
    private fileWithConsumersToReward = new FormData();
    private offers: Array<Offer> = [];
    private readonly rewardRequestPurl = new RewardRequestPurl();
    private rewardPurlForm: FormGroup;

    constructor(
        private dialog: MatDialog,
        private readonly giftService: GiftService,
        private readonly excelService: ExcelService,
        private readonly formBuilder: FormBuilder,
        private readonly operationsService: OperationsService,
        readonly rewardFromFileService: RewardFromFileService
    ) {
    }

    ngOnInit() {
        this.initForms();
        this.initOperations();
        this.loading = false;
    }

    initOperations() {
        this.operations = this.operationsService.getOperations();
        if (this.operations.length) {
            this.selectedOperation = this.operations[0];
            this.operations.forEach(operation => {
                this.operationsService.getOffersByOperationId(operation.id).subscribe((offers) => {
                    offers.forEach(offer => {
                        if (offer.status === 'active') this.offers.push(offer);
                    });
                });
            });
        }
        this.client = this.giftService.client;
    }

    initForms(): void {
        this.rewardPurlForm = this.formBuilder.group(this.rewardRequestPurl);
        this.consumersToRewardFromFileFiltersForm = this.formBuilder.group({
            email: 'all',
            mobile: 'all',
            line: 'all',
            statusAfterReward: 'all'
        });
    }

    fillFormData(target) {
        const file = target.files[0];
        if (file) {
            this.fileWithConsumersToReward.set('file', file, file.name);
            this.loadDataFromFile();
        }
    }

    updateSelectedOperation(operationId) {
        this.selectedOperation = this.operations.find((o) => o.id === Number(operationId));
    }

    getOffersForSelectedOperation() {
        return this.offers
            .filter(offer => offer.operationId === this.selectedOperation.id)
            .sort((a, b) => a.amount === b.amount ? a.name.localeCompare(b.name) : (Number(a.amount) - Number(b.amount)));
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogRewardConfirmationOperationComponent, {
            width: '500px',
            data: {consumersToRewardFromFileStats: this.consumersToRewardFromFileStats}
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.rewardFromFileService.rewardBatch(this.consumersToRewardFromFile, this.selectedOperation.id)
                        .subscribe({
                            error: () => console.error('Error while rewarding by file')
                        });
                }
            }
        });
    }

    hasMatchingOffer(amount): boolean {
        const offerIndex = this.offers.findIndex(o => o.amount.toString() === amount);
        return offerIndex != -1;
    }

    filterConsumersOnAmount() {
        // Custom filter on amount
        for (const consumer of this.consumers) {
            // Don't do anything if there is already an error
            if (consumer.rewardError) continue;

            // Check if an offer with this amount is present
            consumer.amountValid = this.hasMatchingOffer(consumer.amountToReward);
            if (!consumer.amountValid) {
                consumer.status = 'NON INTÉGRÉ';
                consumer.rewardError = 'Montant non trouvé';
                this.consumersToRewardFromFileStats.valid--;
            }
        }
    }

    private loadDataFromFile() {
        this.fileWithConsumersToRewardLoading = true;
        const file = this.fileWithConsumersToReward.get('file');
        this.excelService.importAoAFromExcelFile(file).subscribe({
            next: dataFromFile => {
                const cleanData = this.excelService.cleanDataFromRewardFile(dataFromFile, false, false);
                this.consumersToRewardFromFile = cleanData['consumersToRewardFromFile'];
                this.consumersToRewardFromFileStats = cleanData['consumersToRewardFromFileStats'];
                this.consumers = this.rewardFromFileService.filterConsumersToRewardFromFile(
                    this.consumersToRewardFromFile, this.consumersToRewardFromFileFiltersForm
                );
                this.filterConsumersOnAmount();
                this.fileWithConsumersToRewardLoading = false;
            }
        });
    }
}

export interface DialogData {
    consumersToRewardFromFileStats;
}

@Component({
    selector: 'app-dialog-reward-confirmation',
    templateUrl: 'dialog-reward-confirmation.html',
    standalone: false
})
export class DialogRewardConfirmationOperationComponent {
    constructor(
        private dialogRef: MatDialogRef<DialogRewardConfirmationOperationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    rewardBatch() {
        this.dialogRef.close(true);
    }
}
