import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateService, MsServicesGiftService, Offer, Operation, RewardProgram, RewardProgramStatus} from '@isifid/core';
import {OperationsService} from '../../../../shared/services/operations.service';
import {GiftService} from '../../../../shared/services/gift.service';
import {defaultIfEmpty, finalize, forkJoin, merge, Observable, tap, timer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-admin-reward-programs-manage',
    templateUrl: './manage.component.html',
    styles: `.min-w-145 { min-width: 145px; }`,
    standalone: false
})
export class AdminRewardProgramsManageComponent implements OnInit {
    rewardProgram: RewardProgram;
    createMode: boolean = true;
    rewardProgramForm: FormGroup;
    internalOperations: Operation[] = [];
    operations: Operation[] = [];
    offers: Offer[] = [];
    isLoading: boolean = true;
    isSaving: boolean = false;

    constructor(
        private readonly dateService: DateService,
        private readonly formBuilder: FormBuilder,
        private readonly giftService: GiftService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly _snackBar: MatSnackBar
    ) {}


    ngOnInit(): void {
        this.createMode = this.route.snapshot.url[0]?.path === 'create';
        if (!this.createMode) {
            this.getRegardProgram(this.route.snapshot.params['id']);
        } else {
            this.rewardProgram = new RewardProgram();
            this.initOperations()
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(() => this.initForm());
        }
    }

    get amountOfOfferToRewardOn(): number {
        return this.offers.find(s => Number(s.id) === this.rewardProgram.offerToRewardOn)?.amount as unknown as number;
    }

    get nameOfOperationToRewardFor(): string {
        return this.operations.find(s => s.id === this.rewardProgram.operationToRewardFor)?.name;
    }

    get rewardProgramStatus(): string {
        switch (this.rewardProgram.status) {
        case 'active':
            return 'En cours';
        case 'expired':
            return 'Termine';
        case 'planned':
            return 'Planifié';
        default:
            return '';
        }
    }

    get isSponsorshipOperation(): boolean {
        return this.operations.find(s => s.id === this.rewardProgramForm.controls.operationToRewardFor.value)?.operationType.name === 'SPONSORSHIP';
    }

    goBack(): void {
        this.router.navigate(['/admin/reward-programs']).then();
    }

    save(): void {
        if (this.rewardProgramForm.invalid) return;
        this.isSaving = true;
        const payload = this.rewardProgramForm.value;
        delete payload.operationToRewardOn;
        delete payload.status;

        if (this.createMode) this.createRewardProgram(payload);
        else this.updateRewardProgram(payload);
    }

    private initOperations(): Observable<Offer[][]> {
        this.operations = this.operationsService.getOperations(true, false, true, false);
        this.internalOperations = this.operationsService.getOperations(false, false, false, true);

        // Filter only actives and drafts operations
        this.operations = this.operations.filter(o => o.status !== 'expired');
        this.internalOperations = this.internalOperations.filter(o => o.status !== 'expired');

        // Get offers for internal operations
        const observers = this.internalOperations.map(s => {
            return this.operationsService.getOffersByOperationId(s.id).pipe(tap(offers => s.offers = offers));
        });
        return forkJoin(observers).pipe(defaultIfEmpty([]));
    }

    private initForm(): void {
        this.rewardProgramForm = this.formBuilder.group({
            id: this.rewardProgram.id,
            clientId: [this.giftService.settings.clientId, [Validators.required]],
            startAt: [this.rewardProgram.startAt, [Validators.required]],
            endAt: [this.rewardProgram.endAt, [Validators.required]],
            operationToRewardFor: [this.rewardProgram.operationToRewardFor, [Validators.required]],
            operationToRewardOn: ['', [Validators.required]],
            offerToRewardOn: [this.rewardProgram.offerToRewardOn, [Validators.required]],
            status: [this.rewardProgram.status],
            threshold1: [this.rewardProgram.threshold1, [Validators.required]],
            threshold2: [this.rewardProgram.threshold2]
        });

        // Disable operationToRewardOn for edit mode
        if (!this.createMode) this.rewardProgramForm.controls.operationToRewardOn.disable();

        // Disable operationToRewardOn and offerToRewardOn if there is no internal Operations
        if (!this.internalOperations.length) {
            this.rewardProgramForm.controls.operationToRewardOn.disable();
            this.rewardProgramForm.controls.offerToRewardOn.disable();
        }
        // Set validation for date range controls
        this.setValidationForDateRangeControls();

        this.rewardProgramForm.controls.status.valueChanges.subscribe((s: RewardProgramStatus) => {
            if (s === 'active') {
                this.rewardProgramForm.patchValue({endAt: null});
            } else if (s === 'expired') {
                this.rewardProgramForm.patchValue({endAt: new Date()});
            }
        });

        this.rewardProgramForm.controls.operationToRewardFor.valueChanges.subscribe(() => {
            // Enable or disable threshold 2
            if (this.isSponsorshipOperation) this.rewardProgramForm.controls.threshold2.enable();
            else this.rewardProgramForm.controls.threshold2.disable();
        });

        this.rewardProgramForm.controls.operationToRewardOn.valueChanges.subscribe(operationId => {
            // Reset offers with the selected operation's offers
            this.offers = this.internalOperations.find(s => s.id === operationId).offers;
            if (this.offers.length > 0) this.rewardProgramForm.controls.offerToRewardOn.setValue(this.offers[0].id);
        });
    }

    private setValidationForDateRangeControls(): void {
        // Set validation errors for date range controls
        merge(
            this.rewardProgramForm.controls.startAt.valueChanges,
            this.rewardProgramForm.controls.endAt.valueChanges
        ).subscribe(() => {
            if (!this.rewardProgramForm.controls.startAt.value || !this.rewardProgramForm.controls.endAt.value) {
                this.rewardProgramForm.controls.endAt.setErrors({required: true});
            } else if (new Date(this.rewardProgramForm.controls.startAt.value) >= new Date(this.rewardProgramForm.controls.endAt.value)) {
                this.rewardProgramForm.controls.endAt.setErrors({endAtError: true});
            } else {
                this.rewardProgramForm.controls.endAt.setErrors(null);
            }
        });
    }

    private getRegardProgram(id: number): void {
        if (Number.isNaN(id) || id < 0) {
            this._snackBar.open(`Identifiant du programme de récompense invalide: ${id}`, 'X');
            timer(1000).subscribe(() => this.goBack());
        } else {
            forkJoin([
                this.initOperations(),
                this.msServicesGiftService.getRewardProgram(id)
            ])
                .pipe(finalize(() => this.isLoading = false))
                .subscribe({
                    next: s => {
                        // Initialise offers from all internal operations for edit mode
                        if (!this.createMode) this.offers = this.internalOperations.map(s => s.offers).flat();
                        this.rewardProgram = s[1];
                        this.initForm();
                    },
                    error: error => {
                        console.error(error);
                        this._snackBar.open(`Impossible de trouver le programme de récompense: ${id}`, 'X');
                        timer(1000).subscribe(() => this.goBack());
                    }
                });
        }
    }

    private createRewardProgram(payload: RewardProgram): void {
        delete payload.id;
        this.msServicesGiftService.createRewardProgram(payload)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe({
                next: () => {
                    this._snackBar.open('Un programme de récompense a été créé.', 'X');
                    timer(1000).subscribe(() => this.goBack());
                },
                error: error => {
                    console.error('Unable to create reward program => ', error);
                    this._snackBar.open('Impossible de créer le programme de récompense.', 'X');
                }
            });
    }

    private updateRewardProgram(payload: RewardProgram): void {
        this.msServicesGiftService.updateRewardProgram(payload)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe({
                next: () => {
                    this._snackBar.open('Le programme de récompense a été mis à jour.', 'X');
                    timer(1000).subscribe(() => this.goBack());
                },
                error: error => {
                    console.error(`Unable to update reward program ${payload.id} => `, error);
                    this._snackBar.open(`Impossible de mettre à jour le programme de récompense: ${payload.id}.`, 'X');
                }
            });
    }
}
