import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Branch, Company, FormValidators, HierarchicalLevel, MsDataService} from '@isifid/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GiftService} from '../../../../../shared/services/gift.service';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-branch-manage',
    templateUrl: './manage.component.html',
    standalone: false
})
export class BranchManageComponent implements OnInit {
    loading = true;
    branch: Branch;
    clientCompany: Company;
    clientBranches: Branch[];
    clientLevels: HierarchicalLevel[];

    parentBranch: Branch;
    parentBranchMsg: string;

    branchForm: FormGroup;
    createMode = false;

    constructor(
        private readonly msDataService: MsDataService,
        readonly giftService: GiftService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private readonly router: Router
    ) {
    }

    ngOnInit() {
        forkJoin([
            this.msDataService.getCompanyByClientId(this.giftService.client.id),
            this.msDataService.getBranchesByClientId(this.giftService.client.id)
        ]).subscribe({
            next: ([companyData, branchesData]) => {
                this.clientCompany = companyData;
                this.clientBranches = branchesData;

                const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
                if (id) {
                    this.msDataService.getBranchById(id).subscribe({
                        next: (branch) => {
                            this.branch = branch;
                            this.initForm();
                            this.loading = false;
                        }
                    });
                } else {
                    this.createMode = true;
                    this.initForm();
                    this.loading = false;
                }
            }
        });
    }

    initForm() {
        this.clientLevels = this.giftService.hierarchicalLevels
            .filter(l => l.position > 0 && l.role !== 'GIFT_HQ');

        if (!this.createMode) {
            this.branchForm = this.formBuilder.group({
                externalId: [this.branch.externalId, [Validators.required, Validators.pattern('[0-9]{5}')]],
                name: [this.branch.name, Validators.required],
                parentBranchExternalId: [this.branch.parentBranchExternalId],
                levelId: [this.branch.levelId, Validators.required],
                email: [this.branch.email],
                phone: [this.branch.phone, this.formValidators.isInternationalPhone],
                status: this.branch.status ? this.branch.status : ''
            });
            if (this.branch.parentBranchExternalId) this.selectParentBranch();
        } else {
            this.branchForm = this.formBuilder.group({
                externalId: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
                name: ['', Validators.required],
                parentBranchExternalId: ['', Validators.pattern('[0-9]{5}')],
                levelId: ['', Validators.required],
                address1: ['', Validators.required],
                address2: '',
                zipCode: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
                city: ['', Validators.required],
                email: [''],
                phone: ['', this.formValidators.isInternationalPhone],
                status: ''
            });
        }
    }

    getBranchTitle(): string {
        let title = this.branch.name;
        if (this.branch.address1) title += '<br />' + this.branch.address1;
        if (this.branch.address2) title += '<br />' + this.branch.address2;
        if (this.branch.zipCode && this.branch.city) title += '<br />' + this.branch.zipCode + ' ' + this.branch.city;
        if (this.branch.zipCode && !this.branch.city) title += '<br />' + this.branch.zipCode;
        if (!this.branch.zipCode && this.branch.city) title += '<br />' + this.branch.city;
        return title;
    }

    upperCaseControl(formControlName) {
        this.branchForm.get(formControlName).patchValue(this.branchForm.get(formControlName).value.toUpperCase());
    }

    selectParentBranch() {
        const parentBranchExternalId = this.branchForm.get('parentBranchExternalId').value;
        const parentBranch = this.clientBranches.find(b => b.externalId === parentBranchExternalId);
        if (parentBranch) {
            this.parentBranch = parentBranch;
            delete this.parentBranchMsg;
        } else if (parentBranchExternalId.length > 0) {
            this.branchForm.get('parentBranchExternalId').setErrors({'incorrect': true});
            delete this.parentBranch;
            this.parentBranchMsg = 'Aucune agence trouvée !';
        } else {
            delete this.parentBranch;
            delete this.parentBranchMsg;
        }
    }

    save() {
        // Using email validators make it required
        if (this.branchForm.get('email').value) {
            this.branchForm.get('email').setValidators([Validators.email, this.formValidators.isEmail]);
            this.branchForm.get('email').updateValueAndValidity();
        } else {
            this.branchForm.get('email').clearValidators();
            this.branchForm.get('email').updateValueAndValidity();
        }

        if (this.branchForm.invalid) {
            this._snackBar.open('Le formulaire est érroné', 'X');
            return;
        }
        if (this.createMode) this.create();
        else this.update();
    }

    private create() {
        this.branch = this.branchForm.getRawValue();
        this.branch.companyId = this.clientCompany.id;

        const sameExternalIdBranch = this.clientBranches.find(b => b.externalId === this.branch.externalId);
        if (sameExternalIdBranch) {
            this._snackBar.open('Ce code agence déjà', 'X');
            this.branchForm.get('externalId').setErrors({'incorrect': true});
            return;
        }

        const sameNameBranch = this.clientBranches.find(b => b.name === this.branch.name);
        if (sameNameBranch) {
            this._snackBar.open('Ce nom existe déjà', 'X');
            this.branchForm.get('name').setErrors({'incorrect': true});
            return;
        }

        this.loading = true;
        const data = {
            'companyId': this.clientCompany.id,
            'branches': [this.branch]
        };
        this.msDataService.createBranches(this.giftService.client.id, data).subscribe({
            next: () => {
                this.router.navigate(['/admin/network/branches']).then(() => {
                    this._snackBar.open('Agence créée !', 'X');
                });
            }, error: () => {
                this._snackBar.open('Une erreur est survenue !', 'X');
                this.loading = false;
            }
        });
    }

    private update() {
        const branchData = {};
        branchData['id'] = this.branch.id;
        branchData['externalId'] = this.branchForm.get('externalId').value;
        branchData['name'] = this.branchForm.get('name').value;
        branchData['parentBranchExternalId'] = this.branchForm.get('parentBranchExternalId').value;
        branchData['levelId'] = this.branchForm.get('levelId').value;
        branchData['email'] = this.branchForm.get('email').value;
        branchData['phone'] = this.branchForm.get('phone').value;
        branchData['status'] = this.branchForm.get('status').value;

        if (Object.keys(branchData).length) {
            this.loading = true;
            this.msDataService.updateBranch(this.branch.id, branchData).subscribe({
                next: (branch: Branch) => {
                    this.branch = branch;
                    this._snackBar.open('Agence mise à jour', 'X');
                }, error: () => {
                    console.error('Error while updating branch {}', this.branch);
                    this._snackBar.open('Une erreur est survenue, merci de réessayer', 'X');
                    this.loading = false;
                }, complete: () => this.loading = false
            });
        }
    }
}
