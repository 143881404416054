import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Branch, Budget, ConfigurationService, DateService, MsServicesGiftService, Offer, Operation} from '@isifid/core';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';

import {GiftService} from '../../../../shared/services/gift.service';
import {OperationsService} from '../../../../shared/services/operations.service';
import {OperationStat, StatsDisplay} from '../../models/stats.model';
import {StatsService} from '../../../../shared/services/stats.service';
import {ExcelService} from '@isifid/reward';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute} from '@angular/router';
import {GiftNetworkService} from '../../../../shared/services/gift-network.service';
import {concat, finalize, map, Observable, tap, toArray} from 'rxjs';
import {CustomFieldService} from '../../../../shared/services/custom-field.service';


@Component({
    selector: 'app-stats',
    templateUrl: './stats-operations.component.html',
    styles: `.min-w-130 {min-width: 130px;}`,
    standalone: false
})
export class StatsOperationsComponent implements OnInit {
    budget: Budget = undefined;
    colNames: string[] = [];
    columnsToDisplay: string[] = [];
    customFieldsNames: any[];
    dataSource = new MatTableDataSource<any>();
    disabledCheckbox = false;
    displayedColumns: string[] = [];
    expiredOperation = false;
    formattedStat: StatsDisplay[] = [];
    loading: boolean = true;
    operation: Operation = null;
    operations: Operation[] = [];
    showBudgetData = true;
    statsForm: FormGroup;
    trackingLevel: string = '';

    @ViewChild('sort1') sort1: MatSort;
    @ViewChild('paginator1') paginator1: MatPaginator;

    private branches: Array<Branch> = [];
    private branchHierarchy = [];
    private operationStat: OperationStat;
    private activeOperations: any;
    private allOperations: any;
    private offers: Array<Offer> = [];
    private users: Array<any> = [];

    constructor(
        readonly giftService: GiftService,
        private cdRef: ChangeDetectorRef,
        private readonly customFieldService: CustomFieldService,
        private readonly dateService: DateService,
        private readonly excelService: ExcelService,
        private readonly formBuilder: FormBuilder,
        private readonly giftNetworkService: GiftNetworkService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        private readonly route: ActivatedRoute,
        private readonly statsService: StatsService,
        private readonly _snackBar: MatSnackBar,
        private readonly configurationService: ConfigurationService
    ) {
    }

    ngOnInit(): void {
        this.initOperations();
        if (!this.operations) return;

        this.route.queryParams.subscribe({
            next: (urlParams) => {
                if (urlParams?.level) this.trackingLevel = urlParams.level;
                if (urlParams?.operation) {
                    // Add + to convert string to number for urlParams.operation
                    this.operation = this.operationsService.operations.find(o => o.id === +urlParams.operation);
                    // Init budgets
                    this.initBudgets()
                        .pipe(
                            tap(() => this.resetForm()),
                            finalize(() => {
                                // If trackingLevel get stats
                                if (this.operation && this.trackingLevel) this.getStats();
                            })
                        ).subscribe({error: () => console.error('error initBudgets')});
                } else this.resetForm();
            }
        });
    }

    resetForm(): void {
        this.loading = true;
        this.dataSource = undefined;

        // Get the start date for the form based on:
        // - if there is a budget with a start date
        // - if an operation was selected and has a start date
        // - if first operation start date
        // - Fallback to new Date()
        let startDateToParse: string | Date;
        if (this.budget?.startAt) startDateToParse = this.budget.startAt;
        else if (this.operation?.startDate) startDateToParse = this.operation.startDate;
        else if (this.operations?.[0].startDate) startDateToParse = this.operations[0].startDate;
        else startDateToParse = new Date();

        const startAt = this.dateService.computeDate(startDateToParse, 'yyyy-MM-dd');

        this.statsForm = this.formBuilder.group({
            start: [startAt, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            end: [this.dateService.computeDate(new Date(), 'yyyy-MM-dd'), Validators.required],
            operationId: [this.operation?.id || this.operations[0].id, Validators.required],
            preDefinedPeriod: '',
            useAllOperations: {value: false, disabled: this.disabledCheckbox},
            anonymizeConsumersData: false,
            branchCode: ''
        });
        this.statsForm.controls.operationId.valueChanges.subscribe(() => this.getCustomFieldName());
        this.statsForm.controls.preDefinedPeriod.valueChanges.subscribe(s => this.updateRange(s));
        this.statsForm.controls.useAllOperations.valueChanges.subscribe(s => {
            this.operations = s ? this.allOperations : this.activeOperations;
            this.statsForm.controls.operationId.setValue(this.operations[0]?.id);
        });
        this.initColumns();
        this.loading = false;
    }

    downLoadFromTracking(filters?) {
        // Filter by entity
        if (filters.entityId) {
            this.operationStat.rewardStatsFilter = this.operationStat.rewardStats.filter(s => s.giftUserStat.giftUserId === filters.entityId);
        } else this.operationStat.rewardStatsFilter = this.operationStat.rewardStats;

        this.download(true);
    }

    download(filter?): void {
        if (!this.operationStat) return;

        let aoa = [];
        const colNames = this.statsService.getStatsOperationsColNames(this.giftService.giftNetworkVariables.idName || 'ID', true, this.customFieldsNames)[0];
        this.setStatsTab(aoa, colNames, filter);
        if (this.configurationService.getValueByKey(this.giftService.giftConfiguration, 'statsExportDeleteEmptyColumn')) {
            let emptyColumns = aoa[0].map((s, i) => i);
            const data = aoa.slice(1, (aoa.length - 5));
            data.forEach(s => {
                s.forEach((j, i) => {
                    if (!!j && emptyColumns.includes(i)) emptyColumns = emptyColumns.filter(val => val !== i);
                });
            });
            aoa = [...aoa.slice(0, (aoa.length - 5)).map(s => s.filter((j, i) => !emptyColumns.includes(i))), ...aoa.slice((aoa.length - 5), aoa.length-1)];
        }
        const fileName = this.operationStat.operationName;
        this.excelService.exportAsExcelFileAoA(
            [aoa],
            `${fileName}_${new Date().toISOString().substring(0, 10)}`,
            ['Stats ']
        );
    }

    getStats() {
        if (this.statsForm.invalid || this.loading) return;

        this.loading = true;
        this.dataSource = undefined;
        this.formattedStat = [];
        const filters = {
            start: this.dateService.computeDate(this.statsForm.get('start').value, 'yyyy-MM-dd'),
            end: this.dateService.computeDate(this.statsForm.get('end').value, 'yyyy-MM-dd', 1)
        };

        // If operation got a budget, hide budget data if form is not pristine
        if (!this.statsForm.pristine) this.showBudgetData = false;

        const ope = this.operations.find(o => o.id === this.statsForm.get('operationId').value);

        if (ope?.operationType.name === 'PRINCIPALISATION' && ope.consumerCreationProcess === 'mobfid') {
            this.initColumns('Numéro de dossier Isilis');
        } else {
            this.initColumns();
        }

        this.statsService.getByOperationId(this.statsForm.get('operationId').value, [], filters).subscribe({
            next: (stats) => {
                this.operationStat = stats;
                if (stats?.rewardStats) {
                    this.formattedStat = this.statsService.formatStatsOperation(
                        stats.rewardStats, [], this.offers, this.branches, this.statsForm.controls.anonymizeConsumersData.value
                    );
                    this.branches = this.giftNetworkService.branches;
                    this.branchHierarchy = this.giftNetworkService.branchHierarchy;
                    this.users = this.giftNetworkService.giftUsers;
                    this.dataSource = new MatTableDataSource<unknown>(this.formattedStat);
                    this.dataSource.sort = this.sort1;
                    this.dataSource.paginator = this.paginator1;
                    this.cdRef.detectChanges();
                }
            },
            error: () => {
                console.error('error while getting operation, operationId ' + this.statsForm.get('operationId').value);
                this.loading = false;
            },
            complete: () => this.loading = false
        });
    }

    getCustomFieldName() {
        const operationId = this.statsForm.get('operationId').value;
        // Get offerId for this operationId
        this.operationsService.getOffersByOperationId(operationId).subscribe({
            next: offers => {
                if (offers.length) {
                    const offer = offers[0];
                    // Get custom fields from the operation and offer
                    const customFields = [
                        ...this.customFieldService
                            .getCustomFieldsFromConfiguration(this.operations.find(s => s.id === operationId).configuration)
                            .filter(field => field.saveInDatabase),
                        ...this.customFieldService.getCustomFieldsFromConfiguration(offer.configuration)
                    ];

                    this.customFieldsNames = customFields.map(a => a.name);
                }
            },
            error: () => console.error('error while getting offers for operation, operationId ' + this.statsForm.get('operationId').value)
        });
    }

    updateRange(value: string) {
        this.statsForm.get('start').setValue(this.dateService.computeDate(this.operation.startDate, 'yyyy-MM-dd'));
        // If user select custom range, hide budget related fields
        this.showBudgetData = (value !== 'custom');
    }

    private initColumns(idName?) {
        let customId = this.giftService.giftNetworkVariables.idName || 'ID';
        if (idName) customId = idName;
        this.getCustomFieldName();
        this.colNames = this.statsService.getStatsOperationsColNames(customId, !!this.trackingLevel, this.customFieldsNames)[0];
        this.displayedColumns = this.statsService.getStatsOperationsDisplayedColumns()[0];
        this.columnsToDisplay = this.displayedColumns;
    }

    private initBudgets(): Observable<void> {
        return this.msServicesGiftService.getBudgetsByClientId(this.giftService.client.id)
            .pipe(
                map(budgets => {
                    this.budget = budgets?.find(b => b.operationId === this.operation?.id) || null;
                })
            );
    }

    private initOperations() {
        this.operations = null;

        // Get all operations
        this.allOperations = this.operationsService.getOperations(false, false, false, true);
        if (this.allOperations.length === 0) {
            this._snackBar.open('Aucune opération', 'X');
            return;
        }

        this.activeOperations = this.allOperations.filter((o: Offer) => o.status === 'active');
        if (this.activeOperations.length !== this.allOperations.length) this.expiredOperation = true;

        this.operations = this.activeOperations;
        // if only expired operations disabled checkbox
        if (this.operations.length === 0 && this.allOperations.length > 0) {
            this.operations = this.allOperations;
            this.disabledCheckbox = true;
            this._snackBar.open('Aucune opération active', 'X');
        }

        this.offers = [];
        if (this.operations.length > 0) {
            const observers = this.operations.map(s => this.operationsService.getOffersByOperationId(s.id));
            concat(...observers)
                .pipe(toArray())
                .subscribe(offers => this.offers = offers.flat());
        }
    }

    private getOfferName(offerId: number): string {
        return this.offers.find(o => +o.id === offerId)?.name;
    }

    private addColHierarchy(colNames) {
        const levels = this.giftService.hierarchicalLevels.sort((a, b) => b.position - a.position);
        levels.forEach((level, i) => {
            // Add col for hierarchy (not for first and last level)
            if (i > 0 && i < levels.length - 1) colNames.splice(6, 0, level.title);
        });
        return colNames;
    }

    protected readonly undefined = undefined;

    private setStatsTab(aoa, colNames, filter?): void {
        const nbLevels = this.giftService.hierarchicalLevels.length - 2;
        colNames = this.addColHierarchy(colNames);

        aoa.push(colNames);
        let i = 1;
        let stats = this.operationStat.rewardStats;
        if (filter) stats = this.operationStat.rewardStatsFilter;
        stats.forEach(s => {
            const hierarchyAoa = [];
            s.giftUserStat.branchCode = this.setBranchCode(s.giftUserStat.branchCode);

            if (s.giftUserStat) {
                // Get giftUser levelId and giftUserId
                const giftUser = this.users.find(u => u.email === s.giftUserStat.giftUserEmail);
                s.giftUserStat.giftUserLevelId = giftUser?.levelId;
                s.giftUserStat.giftUserId = giftUser?.giftUserId;

                if (s.giftUserStat.branchCode) {
                    // Get hierarchy for branch
                    const hierarchyForBranch = this.branchHierarchy.find(h => h.branchCode === s.giftUserStat.branchCode);
                    if (hierarchyForBranch?.branchUsers) {
                        // Remove GiftUser with same levelId as giftUserStat
                        hierarchyForBranch.branchUsers = hierarchyForBranch.branchUsers.filter(u => {
                            return u.giftUserId === s.giftUserStat.giftUserId || u.levelId !== s.giftUserStat.giftUserLevelId;
                        });
                        // Format hierarchy to aoa
                        hierarchyForBranch.branchUsers.forEach((user) => {
                            if (this.users.find(u => u.giftUserId === user.id)?.email) {
                                if (hierarchyAoa.length < nbLevels) hierarchyAoa.push(this.users.find(u => u.giftUserId === user.id)?.email || '');
                            }
                        });
                    }
                }
            }

            // Add empty cols for hierarchy if needed
            if (hierarchyAoa.length < nbLevels) {
                while (hierarchyAoa.length < nbLevels) {
                    hierarchyAoa.push('');
                }
            }

            aoa.push([s.giftUserStat.giftUserFirstName, s.giftUserStat.giftUserLastName, s.giftUserStat.giftUserEmail, s.giftUserStat.branchCode,
                this.statsService.getBranchName(s.giftUserStat, this.branches), this.giftNetworkService.getTitle(s.giftUserStat?.giftUserEmail, this.users),
                s.consumerStat.firstName, s.consumerStat.lastName,
                this.statsService.convertDateForSpreadsheet(s.consumerStat.birthDate),
                this.statsService.getExternalId(s.consumerStat.externalId), s.consumerStat.mobile, s.consumerStat.email, s.amount,
                this.statsService.convertDateForSpreadsheet(s.rewardedAt),
                this.statsService.convertDateForSpreadsheet(s.expireAt), this.getOfferName(s.offerId)]);

            // Add hierarchy to aoa
            aoa[i].splice(6, 0, ...hierarchyAoa);

            // Add reward properties
            if (s.rewardProperties) {
                // Store the original rewardProperties object in a temporary variable
                const rewardProperties = s.rewardProperties;
                // Reset s.rewardProperties to an empty object
                s.rewardProperties = {};
                // Get the keys of the rewardProperties object, sort them, and iterate over each key
                Object.keys(rewardProperties).sort().forEach(key => {
                    // Assign the value from the original object to the new sorted object
                    s.rewardProperties[key] = rewardProperties[key];
                });
                for (const property in s.rewardProperties) {
                    if (s.rewardProperties.hasOwnProperty(property)) aoa[i].push(s.rewardProperties[property]);
                }
            }
            i++;
        });
        aoa.push(['']);
        aoa.push(['']);
        aoa.push(['Opération : ', this.operationStat.operationName]);
        aoa.push(['Date début stats', this.statsService.convertDateForSpreadsheet(this.operationStat.startDate)]);
        aoa.push(['Date fin', this.statsService.convertDateForSpreadsheet(this.operationStat.endDate)]);
    }

    private setBranchCode(s) {
        if (s === '-1') return '';
        else return s;
    }
}

