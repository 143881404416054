import {Component, OnInit} from '@angular/core';
import {Budget, MsServicesGiftService, Operation} from '@isifid/core';
import {OperationsService} from '../../../../shared/services/operations.service';
import {GiftService} from '../../../../shared/services/gift.service';
import {UIService} from '../../../../shared/services/ui.service';

@Component({
    selector: 'app-list-operations',
    templateUrl: './operation-list.component.html',
    standalone: false
})
export class OperationListComponent implements OnInit {
    operations: Array<Operation> = [];
    private budgets: Array<Budget> = [];

    constructor(
        public readonly giftService: GiftService,
        public readonly uiService: UIService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService
    ) {
    }

    ngOnInit(): void {
        this.initOperations();
        this.initBudgets();
    }

    initOperations(): void {
        this.operations = this.operationsService.getOperations(true, true, false);
    }

    getBudgetId(operationId): number {
        const budget = this.budgets.find((b) => b.operationId === operationId && b.status === 'active');
        if (budget) return budget.id;
        return null;
    }

    budgetConsumption(operationId: number): number {
        let consumption = -1;
        const budget = this.budgets.find((b) => b.operationId === operationId);
        if (budget) consumption = (budget.amountTotal - budget.amountRemaining) / budget.amountTotal * 100;
        return consumption;
    }

    getEntityName(operation: Operation): string {
        const budget = this.budgets.find(b => b.operationId === operation.id);
        return this.giftService.hierarchicalLevels.find(hl => hl.id === budget.levelId).entity;
    }

    private initBudgets(): void {
        this.msServicesGiftService.getBudgetsByClientId(this.giftService.client.id).subscribe((budgets) => {
            this.budgets = budgets;
        });
    }
}
