import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MsServicesGiftService, Offer, Operation, RewardProgram, RewardProgramStatus} from '@isifid/core';
import {OperationsService} from '../../../../shared/services/operations.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import {defaultIfEmpty, finalize, forkJoin, Observable, tap} from 'rxjs';

@Component({
    selector: 'app-admin-reward-programs-list',
    templateUrl: './list.component.html',
    standalone: false
})
export class AdminRewardProgramsListComponent implements OnInit {
    isLoading: boolean = true;
    dataSource = new MatTableDataSource<any>([]);
    dataSource2 = new MatTableDataSource<any>([]);
    displayedColumns: string[][] = [
        ['operationToRewardFor', 'startAt', 'endAt', 'threshold1', 'threshold2', 'offerToRewardOn', 'status', 'action'],
        ['operationToRewardFor', 'startAt', 'endAt', 'threshold1', 'offerToRewardOn', 'status', 'action']
    ];
    colNames: string[][] = [
        ['Opération', 'Debut', 'Fin', 'Parrainages validés', 'Parrains inscrits', 'Récompense', 'Statut', 'Action'],
        ['Opération', 'Debut', 'Fin', 'Nombre de récompenses', 'Récompense', 'Statut', 'Action']
    ];
    statuses: {value: RewardProgramStatus; label: string}[] = [
        {value: 'active', label: 'En cours'},
        {value: 'expired', label: 'Terminé'},
        {value: 'planned', label: 'Planifié'}
    ];
    private operations: Operation[] = [];
    private offers: Offer[] = [];
    @ViewChild('paginator') private paginator: MatPaginator;
    @ViewChild('paginator2') private paginator2: MatPaginator;

    constructor(
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this.initOperations();
        this.getRewardPrograms();
    }

    private getOperationName(id: number): number {
        return (this.operations.find(s => s.id === id)?.name ?? '') as unknown as number;
    }

    private getOffersName(id: number): number {
        return (this.offers.find(s => Number(s.id) === id)?.name ?? '') as unknown as number;
    }

    private getStatusName(value: RewardProgramStatus): RewardProgramStatus {
        switch (value) {
        case 'active':
            return 'En cours' as RewardProgramStatus;
        case 'expired':
            return 'Termine' as RewardProgramStatus;
        case 'planned':
            return 'Planifié' as RewardProgramStatus;
        default:
            return '' as RewardProgramStatus;
        }
    }

    private filterRewardPrograms(rewardPrograms: RewardProgram[], isSponsorshipOperation: boolean): RewardProgram[] {
        return rewardPrograms
            .filter(s => {
                return this.operations.some(t => {
                    return s.operationToRewardFor === t.id && (t.operationType.name === 'SPONSORSHIP') === isSponsorshipOperation;
                });
            })
            .map(s => ({
                ...s,
                status: this.getStatusName(s.status),
                operationToRewardFor: this.getOperationName(s.operationToRewardFor),
                offerToRewardOn: this.getOffersName(s.offerToRewardOn)
            }));
    }

    private initOperations(): Observable<Offer[][]> {
        this.operations = this.operationsService.getOperations(true, true, false, false);

        const internalOperations = this.operationsService.getOperations(false, true, false, true)
            .filter(s => s.operationType.name === 'INTERNAL');

        // Get all offers
        const observers = internalOperations.map(s => this.operationsService.getOffersByOperationId(s.id));
        return forkJoin(observers)
            .pipe(
                defaultIfEmpty([]),
                tap(offers => this.offers = offers.flat())
            );
    }

    private getRewardPrograms(): void {
        forkJoin([
            this.initOperations(),
            this.msServicesGiftService.getAllRewardPrograms()
        ])
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: ([, rewardPrograms]) => {
                    this.dataSource.data = this.filterRewardPrograms(rewardPrograms, true);
                    this.dataSource2.data = this.filterRewardPrograms(rewardPrograms, false);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource2.paginator = this.paginator2;
                },
                error: () => {
                    console.error('error while getting reward programs');
                    this._snackBar.open('Impossible d\'obtenir des programmes de récompenses.', 'X');
                }
            });
    }
}
