import {Component, OnInit} from '@angular/core';
import {
    EmailAutomationRule,
    MsServicesGiftService,
    MsServicesOperationsService,
    Operation,
    UserService
} from '@isifid/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-automation-rules-list',
    templateUrl: './list.component.html',
    standalone: false
})
export class AutomationOperationRulesListComponent implements OnInit {
    loading = true;
    operation: Operation;
    rules: Array<EmailAutomationRule> = [];
    displayedColumns = ['event', 'rolesTargeted', 'positionsTargeted', 'giftUsersTargeted', 'templateId', 'schedule', 'status', 'actions'];

    constructor(
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly msServicesOperationsService: MsServicesOperationsService,
        public readonly userService: UserService,
        private readonly route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        const operationId = Number(this.route.snapshot.paramMap.get('id'));
        this.msServicesOperationsService.getOperation(operationId).subscribe(
            (operation) => {
                this.operation = operation;
                this.msServicesGiftService.getAllEmailAutomationRules([], {
                    clientId: operation.clientId,
                    operationId: operation.id
                }).subscribe((ruleList) => {
                    this.rules = ruleList;
                    this.loading = false;
                });
            }
        );
    }
}
