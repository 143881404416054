import {Component, OnInit} from '@angular/core';

import {UserService} from '@isifid/core';

import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {GiftService} from '../../../shared/services/gift.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    standalone: false
})
export class DashboardComponent implements OnInit {
    loading = true;
    panel = {drive: true, create: true, animate: true, network: true};

    constructor(
        public readonly giftService: GiftService,
        public readonly sponsorshipService: SponsorshipService,
        public readonly userService: UserService
    ) {
    }

    ngOnInit() {
        this.loading = false;
    }

    hasSponsorship() {
        // Has sponsorship enabled or disabled
        return !!this.sponsorshipService.operation || this.sponsorshipService.getSponsorshipOperationDisabled();
    }

    togglePanel(panel) {
        // switch for panel
        switch (panel) {
        case 'drive':
            this.panel.drive = !this.panel.drive;
            break;
        case 'create':
            this.panel.create = !this.panel.create;
            break;
        case 'animate':
            this.panel.animate = !this.panel.animate;
            break;
        case 'network':
            this.panel.network = !this.panel.network;
            break;
        }
    }
}
